.markdown {
  color: rgba(255, 255, 255, 0.92);
  line-height: 1;
  padding: 30px;
}
.markdown h1, .markdown h2, .markdown h3, .markdown h4 {
  font-weight: 400;
}
.markdown h1, .markdown h2, .markdown h3, .markdown h4, .markdown h5, .markdown p {
  margin-bottom: 24px;
  padding: 0;
}
.markdown h1 {
  font-size: 48px;
}
.markdown h2 {
  font-size: 36px;
}
.markdown h3 {
  font-size: 24px;
}
.markdown h4 {
  font-size: 21px;
}
.markdown h5 {
  font-size: 18px;
}
.markdown span.link {
  color: #61bfc1;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline;
  cursor: pointer;
}
.markdown span.link:hover {
  text-decoration: underline;
}
.markdown a {
  display: none;
}
.markdown ul, .markdown ol {
  padding: 0;
  margin: 0;
}
.markdown ol, li {
  line-height: 24px;
}
.markdown ol li, ul li, .markdown ol li, ul li {
  margin-left: 36px;
}
.markdown p, .markdown ul, .markdown ol {
  font-size: 16px;
  line-height: 24px;
}
.markdown pre {
  padding: 0px 24px;
  max-width: 800px;
  white-space: pre-wrap;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.75em;
  border-radius: 0.5em;
  margin-bottom: 1em;
}
.markdown code {
  font-family: Consolas, Monaco, Andale Mono, monospace;
  line-height: 1.5;
  font-size: 13px;
}
.markdown aside {
  display: block;
  float: right;
  width: 390px;
}
.markdown blockquote {
  border-left: 0.5em solid #eee;
  padding: 0 2em;
  margin-left: 0;
  max-width: 476px;
}
.markdown blockquote cite {
  font-size: 14px;
  line-height: 20px;
  color: #bfbfbf;
}
.markdown blockquote cite:before {
  content: '\2014 \00A0';
}
.markdown blockquote p {
  color: #666;
  max-width: 460px;
}
.markdown hr {
  text-align: left;
  margin: 1em 10em;
  color: #999;
}
.markdown table {
  background: rgba(0, 0, 0, 0.5);
  margin-bottom: 1em;
}
.markdown td, .markdown th {
  padding: 0.5em 1em;
  border: 1px solid #333;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap');

$shadows: #000;
$lightShadows: #111;
$border: #191919;
$text: #DCDCDC;

.image {
  max-width: 80%;
}

.subtitle {
  cursor: default;
  text-shadow: 0 0 .5em $shadows;
  color: $text;
}

.box {
  cursor: default;
  background: rgba(0,0,0,0.3);
  border-radius: .25em;
  padding: 1em;
  max-width: 90%;
  width: 680px;
  transition: all ease-in-out 100ms;
  border: 1px solid $border;
  color: $text;
}

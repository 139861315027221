
.topMenu {
  padding: 1em;
}

$smallBr: 768px;

.modalContent {
  max-width: 75% !important;
  max-height: 85% !important;

  @media (max-width: $smallBr) {
    max-width: 100% !important;
    max-height: 100% !important;
  }
  @media (max-height: $smallBr) {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}

.modalBody {
  margin: 0;
  padding: 0 !important;
}
